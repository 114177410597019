import { useEffect, useState, useRef } from "react";
import {Sun, Moon, Iowa, Check, Close} from './icons'
import {LeftArrow, RightArrow, Hello, SirSmiles, Soup, ErrorBubble, CoolExperiment, LongTime, LessBoxes} from './drawings'


const TECHNOLOGIES = [
  ['React', 'https://reactjs.org', '(with and without hooks)'],
  ['Node', 'https://node.org', ''],
  ['Hapi', 'https://hapi.dev', ''],
  ['Redux', 'hhttps://redux.js.org', ''],
  ['Material UI', 'https://mui.com', ''],
  ['Axios', 'https://axios-http.com', ''],
  ['Fetch', 'https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API', ''],
  ['SWR', 'https://swr.vercel.app', ''],

  ['Network Programming', '', 'Clients and servers all the way down'],
  ['HTTP service orchestration','', '...including retries, headers, token-based authentication'],
  ['Automated Testing'],
  ['Python','https://www.python.org', '(2 and 3)'],
  ['Flask', 'https://palletsprojects.com/p/flask/'],
  ['Redis','https://redis.io','(GCP Hosted)'],
  ['Auth0','https://auth0.com', 'with our own layer of roles and permissions'],

  ['GCP\'s Datastore','https://cloud.google.com/datastore','(Legacy)'],
  ['GCP Pub/Sub','https://cloud.google.com/pubsub'],
  ['GCP Cloud Functions', 'https://cloud.google.com/functions'],
  ['Google Cloud Run','https://cloud.google.com/run'],
  ['Google Cloud Storage','https://cloud.google.com/storage'],
  ['Google App Engine','https://cloud.google.com/appengine'],
  ['Google Kubernetes Engine (GKE)','https://cloud.google.com/kubernetes-engine'],
  ['Datadog','https://datadog.com','..for RUM, Traces and Flame graphs'],

  ['Slack', 'https://slack.com'],
  ['Google Workspace','https://workspace.google.com'],
  ['Github', 'https://github.com'],
  ['Jira', 'https://www.atlassian.com/software/jira'],
  ['Miro', 'https://miro.com'],
  ['Whimsical', 'https://whimsical.com']
]

const LightDarkToggle = () => {
  const [dark, setDark] = useState(true);

  useEffect(() => {
    document.documentElement.className = dark ? 'dark' : '';
  }, [dark]);

  useEffect(() => {
    // sync with system now
    const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    setDark(isDark);

    // watch if the system changes
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const isDark = !!e.matches
      setDark(isDark);
    });
  },[])

  return (
    <div className="mx-1 flex items-center text-blue-700 dark:text-blue-600 select-none">
      <Moon />
      <div className="p-1 w-10 mx-1 border border-blue-700 rounded-full" onClick={() => {
        measureEvent('theme-' + (dark ? 'dark' : 'light'));
        setDark(!dark);
      }}>
        <div className="h-4 w-4 bg-blue-700 rounded-full transition-all"
        style={{
          marginLeft: dark ? "" : "0.9rem"
        }}
        />
      </div>
      <Sun />
    </div>
  )
}

const Image = ({src, description}) => {
  if(!description){
    description = src.replace(/[^a-zA-Z0-9]/,' ');
  }

  return (
    <div className="-mx-6 lg:-mx-32 my-8 py-4 px-2 rounded bg-gray-100 dark:bg-gray-800">
      <img className="object-contain"
          src={src}
          alt={description} />

      <p className="text-gray-500 mt-0 lg:-mt-4 mx-10 lg:mx-24 text-center">{description}</p>
    </div>
    )
}

const TechLink = ({href, children, description}) => {
  const Wrapper = !!href ? ({children, href}) => <a target="__blank" className="underline" href={href}>{children}</a> :
                           ({children}) => <>{children}</>

  return (
    <span
      className=" text-blue-900 dark:text-blue-200 inline-flex items-center text-xl font-mono mr-3 mb-3 bg-blue-100 dark:bg-blue-900 px-2 py-1 rounded"
      style={{transform: `rotate(${Math.random() * 2 - 1}deg)`}}
    >
      <Wrapper href={href}>{children}</Wrapper>
    </span>
  )

}

const H1 = ({children}) => (<h1 className="mt-12 lg:mt-20 mb-6 text-5xl text-blue-700 dark:text-yellow-300 font-hand text-center" >{children}</h1>)
const H2 = ({children}) => (<h2 className="text-xl font-bold" >{children}</h2>)

const P = ({children, size="text-xl", className}) => (<p className={`mt-4 ${size} leading-relaxed ${className}`}>{children}</p>)
const Strong = ({children}) => (<span className="font-bold">{children}</span>)
const Strike = ({children}) => (<span className="line-through opacity-50">{children}</span>)
const Sneak = ({children}) => (<span className="font-italic bg-grey-500">{children}</span>)
const A = ({children, href}) => (<a className="underline decoration-2 underline-offset-2 font-hand" href={href}>{children}</a>)

const Section = ({children}) => (
  <section className="py-4 px-6 dark:text-blue-100 lg:max-w-3xl lg:mx-auto">
    {children}
  </section>
)

const Grid = ({children}) => (<div className="md:grid md:grid-cols-2 lg:-mx-40 lg:grid-cols-3 gap-4">{children}</div>)
const GridBox = ({children, graphic}) => (
  <div className="mt-4 md:mt-0 p-4 rounded bg-blue-100 dark:bg-blue-800">
    {/* <div className="w-20 h-12 m-auto mb-4 object-contain text-blue-800 border border-black">{graphic}</div> */}
    {children}
  </div>
)

const SalaryWidget = ({midpoint}) => (
  <section>
    <div className="flex justify-around items-center my-8">
      <LeftArrow />
      <div>
        <p className="text-4xl lg:text-6xl text-center font-hand">{midpoint}</p>
      </div>
      <RightArrow />
    </div>
  </section>
)

const measureButtonPress = (buttonTitle) => {
  window.gtag('event', 'button', {'value': buttonTitle});
}


const measureEvent = (eventName) => {
  window.gtag('event', eventName);
}

const ResponseButton = ({label, onClick=() => {}}) => {
  const [pressed, setPressed] = useState(false);

  return (
    <button
      onClick={() => {
        if(!pressed){
          measureButtonPress(label);
        }
        setPressed(!pressed);
        onClick();
      }}
      className="flex items-center mt-4 mx-auto text-white text-xl lg:text-3xl rounded-lg px-4 py-2 border-2 border-white hover:bg-indigo-500 shadow-xl">
      {pressed && <div className="mr-5"><Check size={12}/></div>} {label}
    </button>
  )
}

const ContactForm = ({onSubmit = () => {}}) => {
  const Label = ({children}) => (<label className="block text-base md:text-lg mt-4 text-gray-500 dark:text-purple-500">{children}</label>);

  const inputStyle = "text-black dark:text-gray-100 dark:bg-gray-900 block p-1 md:p-4 md:mb-8 shadow-inner border border-blue-300 dark:border-black rounded text-base md:text-xl w-full"


  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");

  const save = () => {
    setIsSaving(true);
    window.fetch('/contact', {
      method:'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name, email, info
      })
    }).then((response) => {
      if (!response.ok) {
        alert('A beans we are having a tough time saving your data, just email me.');
      } else {
        onSubmit();
      }

    }).catch(() => {
      alert('A beans we are having a tough time saving your data, just email me.');
    })

  }

  return (
    <form>
      <Label>Your name </Label>
      <input className={inputStyle} type="text" value={name} onChange={(e) => setName(e.target.value)}  />

      <Label>Email </Label>
      <input className={inputStyle} type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>

      <Label>...anything else you'd like to share?</Label>
      <textarea className={inputStyle + " text-lg"} value={info} onChange={(e) => setInfo(e.target.value)}/>

      <button
        className="flex align-middle mt-8 text-xl font-hand  bg-blue-700 dark:bg-purple-600 rounded p-4 text-white"
        disabled={isSaving}
        onClick = {(e) => {
          e.stopPropagation();
          e.preventDefault();
          save();
        }}
      >
        {isSaving && <Check />}
        Let's do this
      </button>
    </form>
  )
}

const ContactModal = ({onHide = () => {}}) => {
  const cover = useRef(null);
  const [successfulSave, setSuccesfulSave] = useState(false);

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-75 "
      onClick={(e) => {
        // close if the background is clicked
        if (e.target === cover.current){
          measureEvent('contact form abandon');
          onHide();
        }
      }}
    >
      <div ref={cover} className="z-20 flex flex-col justify-center w-full h-screen">
        <div className="mt-12 lg:rotate-1 relative z-30 w-full lg:w-3/5 max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-xl py-4 lg:pt-8 lg:pb-24 drop-shadow-2xl border lg:border-none">
          <button
            className="absolute right-5 dark:text-gray-400 w-6 h-6"
            onClick={() => {
              measureEvent('contact form abandon');
              onHide();
            }}
          >
            <Close size={6}/>
          </button>
          {!successfulSave && <>
            <H1><span className="hidden md:inline">🥳</span> Great, let's talk! <span className="hidden md:inline">🎈</span></H1>

            <div className="mx-4 lg:mx-24 dark:text-purple-500">
              <ContactForm onSubmit={() => {
                measureEvent('form submitted');
                setSuccesfulSave(true);
              }} />
              <P>... or just send me an email <A href="mailto:brian@gaincompliance.com">brian@gaincompliance.com</A> with a brief introduction.</P>
            </div>
          </>}

          {successfulSave && <>
            <H1>Amazing, thank you!</H1>
          </>}
        </div>
      </div>
    </div>
  )
}

function App() {
  const [showContact, setShowContact] = useState(false);

  return (
    <div className="gradient">
      <header className="pt-1 flex flex-row-reverse">
        <LightDarkToggle />
      </header>
      <Section>
        <div className="text-blue-700 dark:text-yellow-300 w-48 lg:w-64 mx-auto">
          <div className="drawn-animation">
            <Hello />
          </div>
        </div>
        <P>We are looking for a <Strong>product-minded experienced software engineer.</Strong> We have a successful product, with a growing customer base - but the code we wrote to get there is messy and needs the attention of a <Strong>fast moving ambitious team eager to simplify, experiment and rebuild large portions of the code.</Strong></P>

        <div className="dark:hidden lg:-mx-20 py-10 pb-10"
             style = {{
              backgroundImage: "url(squiggles-yellow.png)",
              backgroundSize: '100% 100%'
             }}
        >
          <P size="text-xl lg:text-2xl text-center text-amber-800">Senior level engineers who are curious, playful, highly collaborative and can manage their way through (too much) existing code should read on.</P>
        </div>


        <div className="hidden dark:block lg:-mx-20 py-10 pb-10"
             style = {{
              backgroundImage: "url(squiggles-dark.png)",
              backgroundSize: '100% 100%',
             }}
        >
          <P size="text-xl lg:text-2xl text-center text-amber-400">Senior level engineers who are curious, playful, highly collaborative and can manage their way through (too much) existing code should read on.</P>
        </div>


        <H1>The Product</H1>

        <P>We are building an application for the insurance industry. <Strong>Yep</Strong>, it's niche. If you were to smash Google Docs and Google Sheets together, with tens of thousands of formulas tying all of it together and the ability to export standardized PDF reports - you'd roughly have our application. <Sneak>(We wrote <A href="/structured-reporting-white-paper.pdf">a short article</A> about this approach last year, for a slightly deeper dive into this, if you are interested)</Sneak></P>

        <P>The great news is, our competitors are slumbering and we are able to gobble up their picnic. But it's come at a cost, our fast moving first years have been a boon for the business, but have left a mess in our code and infrastructure. The challenge is continuing to iterate on the product feature set, while radically simplifying and streamlining our code and infrastructure.</P>

        <Image src="notes-with-mixed-input-form-and-righ-hand-panel.png" description="A sample screenshot of our application showing mixed data types with the information panel expanded."/>

        <P>Our stack is fairly modern, running on Google infrastructure and written in a mix of Node, Python and React. We are recovering from an over-eager exploration into microservice architecture, and still battle the too-many-http calls daily. Too much of our React code was written before the hooks-evolution and we are drowning in Redux middleware confusion. <Strong>A lot of our business and product patterns are paying off, and it's time to make the engineering platform a reliable and simple foundation to power the coming years.</Strong></P>

        <Image src="rbc-validation-report-2.png" description="The validation dashboard showing remaining items to address before the document can be filed. Also showing the custom rule syntax."/>

        <H1>How We Work</H1>

        <P>You'll be working with a small team of 8-ish engineers with minimal overhead and bureaucracies, who tend to thrive on pairing sessions and are comfortable talking about failures and how we can improve. </P>

        <P>Our engineers are split into two teams, one focused on the parts of our product the customer interacts with (App Team) and the other focused on the services that power the product (Core Team). <Strong>Both teams are full stack</Strong>, with the Apps team tending to have more presentational work, and the Core team more computational and data model focused.</P>

        <P><Strong>Gain Compliance's engineering organization is completely remote.</Strong> The company was founded in Des Moines, Iowa <Iowa /> but has employees scattered across the United States 🇺🇸. We use Github, Slack, Google Suite, Jira and frequent Pop pairing sessions - which means we're looking for a great multi-medium communicator who is comfortable listening, teaching, laughing, arguing, convincing and sharing their ideas in remote-friendly environments.</P>

        <P>We try our best to make planning, designing and building an interactive and collaborative exercise, <Strong>believing that our best work comes when we can all contribute ideas in a safe place.</Strong> To facilitate this we have shared product and business goals, as well as  time to imagine and explore solutions.  We have annual product goals roughly mapped to a quarterly roadmap, with work being broken up into two week sprints (though we use this word pretty loosely). We've borrowed some ideas from agile methodologies, but aren't dogmatic about it.</P>

        <P>Your first few months will be stumbling through understanding our business and existing patterns. This should be driven, if not accelerated, by your curiosity. <Strong>You'll be expected to challenge how we architect, plan, execute and deliver product-focused engineering</Strong> while working alongside our product manager and existing engineering team to build an awareness of the existing implementation while planning to improve it.</P>

        <H1>Our Technical Toolkit</H1>
        <P>We use the following tools, languages, frameworks, patterns and approaches to build our product. Hopefully these things interest you, and you've either had experience with them or you don't mind learning them.</P>
        <div className="mt-2">
          {TECHNOLOGIES.map((tech, i) => {
            const [name, link, description] = tech;
            return <TechLink key={i} href={link} description={description}>{name}</TechLink>
          })}
        </div>

      </Section>

      <Section>
        <H1>Challenges Ahead in 2022</H1>
        <Grid>
          <GridBox graphic={<SirSmiles />}>
            <H2>Snappy Customer Experience</H2>
            <P className="text-lg">Get our web app as performant as a desktop experience. Too often our interactions can take seconds, I bet we can get this down to 100ms.</P>
          </GridBox>

          <GridBox graphic={<Soup />}>
            <H2>Cleaning up our Redux soup</H2>
            <P className="text-lg">Our client-facing react application is 5 years old, written before hooks were commonplace. It makes heavy use of Redux and middleware to load state from the services. We are in the midst of migrating away from large shared state and toward simpler components and state management. Halp us.</P>
          </GridBox>

          <GridBox graphic={<ErrorBubble />}>
            <H2>Threading errors from our connected services up to the user</H2>
            <P className="text-lg">Large requests from users are frequently partitioned to smaller tasks handled by Google Cloud Functions. This pattern is convenient until an error occurs 3 service layers deep and needs to be communicated to the customer non-nerd english.</P>
          </GridBox>

          <GridBox graphic={<LessBoxes />}>
            <H2>Simplifying GCP Product Usage</H2>
            <P className="text-lg">We'd like to reduce the number of GCP compute product's we use. We are currently running on GAE, Cloud Run, Cloud Functions, and GKE. We've outgrown App Engine and need to migrate our apps to GKE or Cloud Run.</P>
          </GridBox>

          <GridBox graphic={<LongTime />}>
            <H2>Managing long running tasks</H2>
            <P className="text-lg">Our app can export large PDF and zip files. Doing so can take up to a dozen minutes. We need to gain competence handling long running jobs that connect to multiple services running atop the elasticity of google's serverless infrastructure.</P>
          </GridBox>

          <GridBox graphic={<CoolExperiment />}>
            <H2>Comfortable Experimenting</H2>
            <P className="text-lg">Embracing fast feedback and quick explorations isn't part of our DNA, but we all agree it should be. We are slowly getting there, and need opinionated and experienced individuals to accelerate this goal.</P>
          </GridBox>
        </Grid>
        <P>In the spirit of openness, you can read our top three themes for our engineering team this year - <A href="/2022-engineering-themes.pdf">Engineering Themes 2022</A>.</P>


        <H1>Benefits</H1>
        <P>Gain Compliance is a late stage startup. We are focused on producing a great product while developing a sustainable culture. While we don't have all the perks of a large organization, we make up for this with incredible autonomy and the personal relationships of a small company. </P>

        <P>We have full company-paid medical, vision and dental health benefits. We are happy to share our complete health benefits packet upon request.</P>

        <P>An unlimited vacation policy for all employees with no blackout dates.</P>

        <P>We offer a flexible four week parental leave for new parents.</P>

        <H1>How We Hire</H1>
        <P>Switching jobs is a huge change, so we've tried to make our hiring practices transparent with minimal stress. Every person gets the same experience. Our technical interview is based on a short 1 or 2 hour take home exercise and follow up conversation with the team. If you are interested in an opportunity to join our team and help us build this product, you can expect:</P>

        <ol className="text-xl list-decimal ml-8">
          <li className="mt-4">A 30 minute call with Brian, the Head of Product Development. He'll share a bit more about the company, listen to what you are looking for, and answer any questions you might have.</li>

          <li className="mt-4">If that goes well, we'll send you instructions for our technical interview. You can <A href="https://docs.google.com/document/d/1nFp5qJQxAOrwCKHQNeJTJs9jdYvuCV_yLEPTyCPQpDQ/edit#">read those now</A> if you'd like. This is a pretty fun exercise (see <A href="https://game-of-life-service-ai3nmiz7aa-uc.a.run.app/viewer/i45mZptT-JOU-fFQyxjsAuwal4vXTw">sample submission</A>) that mirrors a lot of the practical work we do. Once you are ready (usually within a week or two) we'll schedule the 90 minute technical interview to talk through your code. You'll never need to send us your code - it's all done through screen sharing. We'll have a couple of our engineers join this call and ask you questions about your solution.</li>

          <li className="mt-4">Our final step will be an interview with Jason, our CTO, where you can ask the questions.</li>
        </ol>

        <P>💡Some candidates prefer more time to ask questions and make sure this is the right job for them. In which case I'd love to talk more!</P>

      </Section>


      <section className="mt-6 pt-8 pb-4 bg-blue-900 text-white moving-gradients">
        <div className="text-center">
          <P className="text-2xl">Now, you know it all!<Strong> ...Are you interested?</Strong></P>
        </div>
        <div className="mt-8">
          <button
            className="block my-12 mx-auto font-hand text-white text-3xl lg:text-6xl shadow-xl uppercase rounded-lg px-8 py-4 border-4 border-white hover:bg-indigo-500"
            onClick={() => {
              measureEvent('contact form opened');
              setShowContact(true)
            }}
          >
            🌟Heck Yes!🌟
          </button>

          <ResponseButton label="not right now, but maybe later? 🕑" />
          <ResponseButton label="tbh, this job sounds kinda boring 😴" />
          <ResponseButton label="you aren't paying enough 💵" />
          <ResponseButton label="bright gradients burn my eyeballs 😎" />

          <P className="text-center text-sm lg:text-xl mt-12 mx-4">for additional information or inquries please email me, Brian Anderson, at <A href="mailto:brian@gaincompliance.com">brian@gaincompliance.com</A>.</P>
        </div>
      </section>
      {showContact && <ContactModal onHide={() => setShowContact(false)}/>}

    </div>
  );
}

export default App;
